.featured-solution-grid {
  grid-template-rows: auto;
}

.featured-solution-grid div:not(.grid-end)::after {
  content: "";
  position: absolute;
  background-color: #E3DFDA;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: -1px;
}

.featured-solution-item {
  grid-row-start: var(--grid-row)
}