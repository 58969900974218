.table-custom-style table {
  box-sizing: border-box;
  font-size: smal;
  border-spacing: 0;
}

.table-custom-style tr,
th,
td {
  text-align: left;
}

.custom-table * {
  margin: 0 !important;
}

.custom-table th {
  border-top: 1px solid rgb(147, 140, 133);
}

.custom-table th:last-child {
  border-right: 1px solid rgb(147, 140, 133);
  border-top-right-radius: 0.25rem;
}

.custom-table th:first-child {
  border-left: 1px solid rgb(147, 140, 133);
  border-top-left-radius: 0.25rem;
}

.custom-table tr > td:first-child {
  border-left: 1px solid rgb(147, 140, 133);
}

.custom-table tr:last-child td:first-child {
  border-bottom-left-radius: 0.25rem;
}

.custom-table tr:last-child > td:last-child {
  border-bottom-right-radius: 0.25rem;
}

.custom-table thead > tr:first-child {
  background: rgb(247, 246, 244) !important;
  font-weight: bold;
}

.custom-table tr:nth-child(even) {
  background: rgb(242, 240, 237);
}

.custom-table tr:last-child > td:first-child {
  border-bottom: 1px solid rgb(147, 140, 133);
}

.custom-table tr:last-child > td:last-child {
  border-bottom: 1px solid rgb(147, 140, 133);
}

.custom-table tr > td:last-child {
  border-right: 1px solid rgb(147, 140, 133);
}

.logo--container {
  position: absolute;
  top: -3.3rem;
  left: -17rem;
  height: 8.1rem;
  overflow: hidden;
  width: 15rem;
  z-index: 1;
}

.table-item {
  width: min-content;
  max-width: 100%;
}

@media (max-width: 1024px) {
  .logo--container {
    width: 7rem;
    top: 1rem;
    left: -8rem;
    height: 3.8rem;
    overflow: hidden;
    z-index: 1;
  }
}

@media (max-width: 912px) {
  .logo--container {
    display: none;
  }
}

.body-02 {
  font-weight: 450;
}

.custom-weight {
  font-weight: 450;
}

.h-1 {
  height: 3px;
}

.box {
  height: 178px;
  width: 215px;
}

.divider {
  width: 2px;
  height: 93px;
}

.tracker-dot {
  z-index: 2;
  height: 16px;
  width: 16px;
}

.grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: 2px solid #004aad;
}

.first-row {
  border: none;
}

.second-row {
  border: none;
}

/* Horizontal timeline line */
.timeline-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #004aad;
  z-index: 0;
}

.timeline-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  background-color: #324fa5;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.timeline-marker-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.first-row::before {
  border-left: 3px solid #004aad;
  height: 30px;
}

.second-row::before {
  border-left: 3px solid #004aad;
  height: 70px;
  margin-top: -4px;
}

.first-row:nth-child(5)::after {
  content: '';
  height: 55px;
  border-right: 3px solid #004aad;
  margin-bottom: -7px;
}


.second-row:nth-child(11) {
  border-right: 3px solid #004aad;
  height: 28px;
}


.first-row::before {
  content: '';
  position: absolute;
  left: 0;
  top: 90%;
  transform: translateY(-10%);
  border-left: 2px solid #004aad;
}

.second-row::before {
  content: '';
  position: absolute;
  left: 0;
  height: 40px;
  transform: translateY(-40%);
  border-left: 2px solid #004aad;
}

.first-row:nth-child(1)::before {
  height: 50px;
  border-right: 1px solid #004aad;
}

.first-row:nth-child(5)::after {
  content: '';
  height: 50px;
  border-right: 3px solid #004aad;
  margin-bottom: -25px;
}

.second-row:nth-child(7)::before {
  border-right: 1px solid #004aad;
  height: 40px;
}

.second-row:nth-child(11)::before {
  height: 50px;
}

.home-page-text {
  color: white;
  font-weight: 900;
}

.home-page-1 {
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.border{ 
    --border-color: var(--sdf-color-border-purpose-black,#938C85);
}

.relative {
  position: relative;
}

.tooltip-content {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: white;
  font-weight: 100;
  font-size: small;
  color: black;
  padding: 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 100;
  max-width: 20rem;
  word-wrap: break-word;
}

.relative:hover .tooltip-content {
  visibility: visible;
}

@media (max-width: 397px) {
.img-wrapper {
  height: 12rem;
}
}
