:root {
    --background-repeat: no-repeat;
    --background-position-y: 22px;
    --padding-top: 21px;
    --padding-left: 26px;
    --font-weight: 800;
    --font-size: 18px;
    --line-height: 40px;
}

.special-section {
    padding: 0.4rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    border-radius: 0.375rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.border{ 
    --border-color: var(--sdf-color-border-purpose-black,#938C85);
}

.tip {
    background-color: #E8EFFA;
}

.info {
    background-color: #E8EFFA;
}

.result {
    background-color: #F2F0ED;
}

.note {
    background-color: #F2F0ED;
}

.important {
    background-color: #F8EED9;
}

.tip::before {
    content: "Tip";
    background-image: url('../../../assets/images/icons/alert-notification.svg');

    background-repeat: var(--background-repeat);
    background-position-y: var(--background-position-y);
    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    font-weight: var(--font-weight);
    font-size: var(--font-size);
    line-height: var(--line-height);
}

.info::before {
    content: "Info";
    background-image: url('../../../assets/images/icons/alert-notification.svg');

    background-repeat: var(--background-repeat);
    background-position-y: var(--background-position-y);
    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    font-weight: var(--font-weight);
    font-size: var(--font-size);
    line-height: var(--line-height);
}

.result::before {
    content: "Result";
    background-image: url('../../../assets/images/icons/alert-info.svg');

    background-repeat: var(--background-repeat);
    background-position-y: var(--background-position-y);
    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    font-weight: var(--font-weight);
    font-size: var(--font-size);
    line-height: var(--line-height);
}

.note::before {
    content: "Note";
    background-image: url('../../../assets/images/icons/alert-info.svg');

    background-repeat: var(--background-repeat);
    background-position-y: var(--background-position-y);
    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    font-weight: var(--font-weight);
    font-size: var(--font-size);
    line-height: var(--line-height);
}


.important::before {
    content: "Important";
    background-image: url('../../../assets/images/icons/status-flagged.svg');

    background-repeat: var(--background-repeat);
    background-position-y: 23px;
    padding-top: var(--padding-top);
    padding-left: 23px;
    font-weight: var(--font-weight);
    font-size: var(--font-size);
    line-height: var(--line-height);
}



a {
    color: rgb(50, 79, 165);
}

.dev-portal-custom-style td > p {
    margin: 0 !important;
}

.dev-portal-custom-style img {
    max-width: 100% !important;
    height: auto !important;
}

.dev-portal-custom-style table {
    box-sizing: border-box;
}

.dev-portal-custom-style tr, th, td {
    text-align: left;
    padding: 15px;
}

.table-without-thead .table-with-thead * {
    margin: 0 !important;
}

.table-with-thead th {
    border-top: 1px solid rgb(147, 140, 133);
    border-bottom: 1px solid rgb(147, 140, 133);
    border-right: 1px solid rgb(147, 140, 133);
}

.table-with-thead th:last-child {
    border-right: 1px solid rgb(147, 140, 133);
    border-top-right-radius: 0.25rem;
}

.table-with-thead th:first-child {
    border-left: 1px solid rgb(147, 140, 133);
    border-top-left-radius: 0.25rem;
}

.table-with-thead td {
    border-bottom: 1px solid rgb(147, 140, 133);
    border-right: 1px solid rgb(147, 140, 133);
}

.table-with-thead tr > td:first-child {
    border-left: 1px solid rgb(147, 140, 133);
}

.table-with-thead tr:last-child > td:first-child {
    border-bottom-left-radius: 0.25rem;
}

.table-with-thead tr:last-child > td:last-child {
    border-bottom-right-radius: 0.25rem;
}

.table-with-thead thead > tr:first-child {
    background: rgb(247, 246, 244) !important;
    font-weight: bold;
}

.table-with-thead tr:nth-child(even) {
    background: rgb(242, 240, 237);
}

/*end of styles for tables with thead*/

.table-without-thead tr:nth-child(odd) {
    background-color: rgb(242, 240, 237);
}

.table-without-thead tr:first-child > td:first-child {
    border-left: 1px solid rgb(147, 140, 133);
}

.table-without-thead tr:first-child > td {
    border-top: 1px solid rgb(147, 140, 133);
    border-bottom: 1px solid rgb(147, 140, 133);
    border-right: 1px solid rgb(147, 140, 133);
}

.table-without-thead tr:not(tr:first-child) > td {
    border-bottom: 1px solid rgb(147, 140, 133);
    border-right: 1px solid rgb(147, 140, 133);
}

.table-without-thead tr:not(tr:first-child) > td:first-child {
    border-left: 1px solid rgb(147, 140, 133);
}

.table-without-thead tr:first-child {
    background: rgb(247, 246, 244) !important;
    font-weight: bold;
}

.table-without-thead tr:first-child > td:first-child {
    border-top-left-radius: 0.25rem;
}

.table-without-thead tr:first-child > td:last-child {
    border-top-right-radius: 0.25rem;
}

.table-without-thead tr:last-child > td:first-child {
    border-bottom-left-radius: 0.25rem;
}

.table-without-thead tr:last-child > td:last-child {
    border-bottom-right-radius: 0.25rem;
}


