.img-size {
  height: 64px;
  width: 64px;
}

.shadow {
  box-shadow: 0px 8px 16px 0px rgba(38, 35, 33, 0.16);
  box-shadow: 0px 0px 8px 0px rgba(38, 35, 33, 0.04);
}

.font-weight-450 {
    font-weight: 450;
}

.selected-item {
  box-shadow: 0px 0px 0px 4px #fff, 0px 0px 0px 6px #324fa5;
}

.sup {
  font-size: 50%;
  vertical-align: super;
}

.highlight {
  border-color: #324fa5;
  outline: 2px solid #324fa5;
  outline-offset: 2px;
}

.break::after {
  content: "\A";
  white-space: pre;
}
