.introducing-text-container {
  height: fit-content;
}

.introducing-image-container {
  width: var(--introducingImageSize);
  height: var(--introducingImageSize);
}

.introducing-image-shape {
  border-radius: 56% 44% 70% 30% / 50% 59% 41% 50%;
}

.introducing-image {
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
}