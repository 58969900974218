.api-explorer-header-text {
  height: fit-content;
}

.api-explorer-header-grid {
  grid-template-columns: 1fr;
}

@media screen and (min-width: 768px) {
  .api-explorer-header-grid {
    grid-template-columns: auto 1fr;
  }
}
