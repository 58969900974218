/* TODO - get exact radius values from Troy */
.hero-image-shape {
  border-radius: 40% 60% 59% 41% / 55% 42% 58% 45%;
}

.hero-image-outline {
  rotate: -80deg;
  z-index: -1;
  width: 104%;
  height: 104%;
}

@media (min-width: 1280px) and (max-width: 1400px) {
  .hero-image {
    white-space: normal;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 1.5;
  }
}

@media (min-width: 640px) and (max-width: 750px) {
  .hero-image {
    white-space: normal;
  }
}
