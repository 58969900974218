.timeline-container {
  border-color: #b2aba5;
}

.cursor {
  cursor: pointer;
}


.timeline-grid::before {
  content: "";
  position: absolute;
  background-color: #324fa5;
  height: auto;
  width: 3px;
  left: 50%;
  top: calc(100% / (var(--timeline-items) + 1));
  bottom: calc(100% / (var(--timeline-items) + 1));
  margin: -1.5px;
}

.timeline-grid::after {
  content: "";
  grid-column-start: 2;
  grid-row-start: 1;
}

.timeline-item::after {
  content: "";
  position: absolute;
  background-color: #324fa5;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  left: 50%;
  margin: -0.5rem;
  top: calc(100% * (var(--timeline-item-index) + 1) / (var(--timeline-items) + 1));
  margin: -0.5rem;
}

.timeline-focus.timeline-item::after {
  box-shadow: 0px 0px 0px 4px #fff, 0px 0px 0px 6px #324fa5;
}

.timeline-carousel {
  width: calc(100% * round(up, var(--timeline-items) / var(--visible-items)));
}

.timeline-carousel-item-hr {
  top: -1rem;
}

@media screen and (min-width: 1024px) {
  .timeline-grid::before {
    height: 3px;
    width: auto;
    left: calc(100% / (var(--timeline-items) + 1));
    right: calc(100% / (var(--timeline-items) + 1));
    top: 50%;
  }

  .timeline-grid::after {
    grid-column-start: 1;
    grid-row-start: 2;
  }

  .timeline-item::after {
    top: 50%;
    left: calc(100% * (var(--timeline-item-index) + 1) / (var(--timeline-items) + 1));
  }
}