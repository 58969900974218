#root {
    --color_dark_grey: #222222;
    --color_blue: #26328c;
    --color_adp_red: #d0271d;
    --color_medium_grey: #f7f6f4;
}

.print-container {
    height: 100%;
}

.print-chapter {
    display: none;
}

.print-break {
    break-after: page !important;
}

.adp-logo {
    position: absolute;
    right: 25px;
    top: 845px;
}

.chapter-introduction-container {
    height: 7in;
    background-image: url("../../../../../assets/images/guides-pdf/guide-legal-background.png");
    background-size: cover !important;
    background-repeat: no-repeat !important;
    -webkit-print-color-adjust: exact;
    padding: 265px 52px 0 300px !important;
}


.chapter-introduction-chapter-number {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.23px;
    color: var(--color_dark_grey) !important;
}

.chapter-introduction-chapter-title {
    padding-top: 10px;
    font-size: 42px;
    font-weight: 600;
    line-height: 1.1;
    letter-spacing: 0.59px;
    color: var(--color_blue) !important;
}

.chapter-introduction-guide-title {
    padding-top: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: 0.59px;
    color: var(--color_blue) !important;
}

.published-date, .last-modified {
    padding-top: 20px;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--color_dark_grey) !important;
}

.guide-legal-container {
    height: 7in;
    background-image: url("../../../../../assets/images/guides-pdf/guide-legal-background.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    -webkit-print-color-adjust: exact;
    padding: 265px 37px 0 300px !important;
    font-weight: 700;
    color: var(--color_dark_grey) !important;
}

.guide-legal-title {
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: 0.25px;
}

.guide-legal-info {
    padding-top: 20px;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.14px;
}

.guide-legal-info > * {
    margin-bottom: 15px;
}

.guide-legal-container .last-modified .published-date {
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.14px;
}


.chapter-contents-list-container {
    height: 8.5in;
    background-image: url("../../../../../assets/images/guides-pdf/guide-legal-background.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    -webkit-print-color-adjust: exact;
    padding: 125px 37px 0 300px !important;
    font-size: 10px;
}

.chapter-contents-list-title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.34px;
    color: var(--color_dark_grey) !important;
    padding-bottom: 70px;
}

.chapter-contents-list-chapter-number {
    padding-top: 20px;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.14px;
    color: var(--color_adp_red) !important;
    margin-bottom: 6px;

}

.chapter-contents-list-chapter-title {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.14px;
    color: var(--color_dark_grey) !important;
}

.chapter-contents-list-headings-title {
    margin-top: 8px;
}


.print-chapter .chapter-content-container {
    margin: 0 48px;

}

.chapter-content-chapter-number {
    font-size: 12px;
    font-weight: lighter;
    letter-spacing: 0.17px;
    color: var(--color_adp_red) !important;
}

.chapter-content-chapter-title {
    font-size: 24px;
    font-weight: normal;
    padding-bottom: 30px;
    letter-spacing: 0.34px;
    color: var(--color_dark_grey) !important;
    padding-top: 5px;
}

.chapters-heading-3 {
    margin-left: 20px;
}

.chapters-heading-4 {
    margin-left: 40px;
}

.chapters-heading-5 {
    margin-left: 60px;
}

.chapters-heading-6 {
    margin-left: 80px;
}


@media print {
    .no-print {
        display: none !important;
    }

    .print-chapter {
        display: block;

        height: 100%;
    }
}

@media print {
    @page {
        size: letter;
        margin: 48px 25px;
    }

    .adp-logo {
        width: 84px !important;
        height: 61px !important;
    }
}



