.need-box-icon-container {
  width: 3.5rem;
  height: 3.5rem;
}

/* TODO - get exact radius values from Troy */
.need-box-icon-shape {
  border-radius: 69% 31% 66% 34% / 47% 53% 47% 53%;
  z-index: -1;
}

.start-box-border {
  border-color: #e3dfda;
}