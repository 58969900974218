.use-case-compatible {
  height: 2rem;
}

.solution-image-container {
  width: 4rem;
  height: 4rem;
}

.solution-image-shape {
  border-radius: 56% 44% 70% 30% / 50% 59% 41% 50%;
}
