.go-live-readiness-section {
  position: relative;
  grid-column: span 2 / span 2;
  font-weight: bold;
  color: #333;
  border-left: 2px dotted #aaa;
  border-right: 2px dotted #aaa;
  margin-top: -7rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.readiness-gap {
  display: grid;
  column-gap: 1rem;
  row-gap: 0.5rem;
}

.go-live-readiness-section ul li {
  list-style-position: outside; 
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.header {
  visibility: hidden;
  text-align: center;
  height: 2rem;
}

@media (max-width: 768px) {
  .readiness-gap {
    column-gap: 0.5rem;
  }
}

@media (min-width:1280px) and (max-width: 1476px) {
    .readiness-gap {
      column-gap: 8rem;
    }
  }
  
  @media (min-width: 1024px) and (max-width:1280px){
    .readiness-gap {
      column-gap: 4rem;
    }
  }
  
  
  @media (min-width: 1481px) {
    .readiness-gap {
      column-gap: 14rem;
    }
  }
