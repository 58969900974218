#root {
    --color_dark_grey: #222222;
    --color_blue: #26328c;
    --color_adp_red: #d0271d
}

@font-face {
    font-family: "TaubSans";
    src: local("TaubSans"),
    url("../../../../../assets/fonts/TaubSans/TaubSans-Regular.ttf") format("truetype");
}

.print-container {
    height: 100%;
}

.print-container-font {
    font-family: TaubSans, 'sans-serif' !important;
}

.print-guide {
    display: none;
}

.print-break {
    break-after: page !important;
}

.print-break-chapters {
    display: block;
    clear: both;
    height: 0;
    line-height: 0;
    overflow: hidden;
    break-inside: avoid;
    page-break-before: always;
}

.adp-logo {
    position: absolute;
    right: 25px;
    top: 845px;
}

@media print {
    .adp-logo {
        width: 84px !important;
        height: 61px !important;
    }
}

.guide-introduction-container {
    height: 5.3in;
    background-image: url("../../../../../assets/images/guides-pdf/guide-cover-background.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    -webkit-print-color-adjust: exact;
    padding: 425px 52px 0 300px !important;
}

.guide-introduction-guide-text {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.23px;
    color: var(--color_dark_grey) !important;
}

.guide-introduction-guide-title {
    padding-top: 10px;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: 0.59px;
    color: var(--color_blue) !important;
}

.published-date, .last-modified {
    padding-top: 20px;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--color_dark_grey) !important;
}

.guide-legal {
    height: 7.1in;
    background-image: url("../../../../../assets/images/guides-pdf/guide-legal-background.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    -webkit-print-color-adjust: exact;
    padding: 265px 37px 0 300px !important;
    font-weight: 700;
    color: var(--color_dark_grey) !important;
}

.guide-legal-title {
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: 0.25px;
}

.guide-legal-info {
    padding-top: 20px;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.14px;
}

.guide-legal-info > * {
    margin-bottom: 15px;
}

.guide-contents-container {
    height: 100%;
    background-image: url("../../../../../assets/images/guides-pdf/guide-legal-background.png") !important;
    background-size: 100% auto !important;
    background-repeat: no-repeat !important;
    -webkit-print-color-adjust: exact;
    padding: 125px 37px 0 300px !important;
}

.guide-contents-chapters {
    font-size: 10px;
}

.guide-contents-title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.34px;
    color: var(--color_dark_grey) !important;
    padding-bottom: 70px;
}

.guide-contents-chapter-number {
    padding-top: 20px;
    padding-bottom: 5px;
    font-weight: normal;
    letter-spacing: 0.14px;
    color: var(--color_adp_red) !important;
}

.guide-contents-chapter-title {
    font-weight: 700;
    letter-spacing: 0.14px;
    color: var(--color_dark_grey) !important;
}

.chapter-contents-list-headings-title {
}

.guide-chapters-content-container {
    /*margin-left: 15px;*/
    /*margin-right: 15px;*/
}

.chapter-content-container {
    padding-top: 10px;
    padding-bottom: 20px;
    margin: 0 37px;

}


.print-guide .chapter-content-chapter-number {
    font-size: 12px !important;
    font-weight: lighter;
    letter-spacing: 0.17px;
    color: var(--color_adp_red) !important;
}

.print-guide .chapter-content-title {
    font-size: 24px;
    padding-top: 5px;
    padding-bottom: 20px;
    font-weight: normal;
    letter-spacing: 0.34px;
    color: var(--color_dark_grey) !important;
}

.chapters-heading-3 {
    margin-left: 20px;
}

.chapters-heading-4 {
    margin-left: 40px;
}

.chapters-heading-5 {
    margin-left: 60px;
}

.chapters-heading-6 {
    margin-left: 80px;
}

body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
}


@media print {
    .no-print {
        display: none !important;
    }

    .print-guide {
        display: block;
    }
}

/* This piece of css works for ChapterPrintView component too */
@media print {
    @page {
        size: letter;
        margin: 48px 25px;
    }

    body {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }

    .cc-window[aria-label="cookieconsent"], button.cmp-revoke-consent {
        display: none !important;
    }

    .guide-content-container h1 {
        font-size: 24px;
    }

    .guide-content-container h3 {
        font-size: 16px;
    }

    .guide-content-container p {
        font-size: 11px;
        font-weight: normal;
    }

    .guide-content-container ul, ol {
        font-size: 11px;
    }

    .guide-content-container pre {
        font-size: 8px;
    }

    .guide-content-container img {
        position: relative;
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 100% !important;
        height: auto !important;
        page-break-inside: avoid;
    }

    .guide-content-container td {
        word-break: break-word;
    }

    .guide-content-container tr, td {
        font-size: 9px;
    }

    .guide-content-container table {
        width: 100%;
        border-collapse: collapse !important;
    }

    .guide-cover-logo {
        width: 84px !important;
        height: 61px !important;
    }
}

