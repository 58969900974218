.expert-consulting-point-container div {
  flex-basis: 100%;
}

.expert-consulting-point-container::after {
  content: "";
  background-color: #726c66;
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  top: 50%;
  margin: -0.5px 0;
}

@media screen and (min-width: 1024px) {
  .expert-consulting-point-container::after {
    width: 1px;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 50%;
    margin: 0 -0.5px;
  }
}
