.hero-image-recruiting {
  top: -1rem;
  left: 5rem;
}

.hero-image-benefits {
  top: 3rem;
  left: -6rem;
}

.hero-image-onboarding {
  top: 4rem;
  left: 16rem;
}

.hero-image-payroll {
  top: 9rem;
  left: -5rem;
}

.hero-image-data {
  top: 10rem;
  left: 17rem;
}

.hero-image-time {
  top: 15rem;
  left: -3rem;
}

.hero-image-qualifications {
  top: 16rem;
  left: 12rem;
}

.hero-image-benefits-int {
  top: -1rem;
  left: 5rem;
}

.hero-image-core-int {
  top: 4rem;
  left: 16rem;
}

.hero-image-hcm-int {
  top: 11rem;
  left: 17rem;
}

.hero-image-hr-int {
  top: 18rem;
  left: 5rem;
}

.hero-image-payroll-int {
  top: 12rem;
  left: -4rem;
}

.hero-image-time-int {
  top: 5rem;
  left: -6rem;
}

.wfn {
  top: 0.7rem;
  left: 12rem;
}

.recruiting {
  top: 6rem;
  left: -2rem;
}

.nextgen {
  top: 5.5rem;
  left: 19rem;
}

.run {
  top: 12rem;
  left: 23rem;
}

.hcm {
  top: 19rem;
  left: 21rem;
}

.vantage {
  top: 24rem;
  left: 9rem;
}

.compliance {
  top: 12.2rem;
  left: -3rem;
}

.enterprise {
  top: 18rem;
  left: 1rem;
}

.vantage-in {
  top: 24rem;
  left: 9rem;
}

.wfn-in {
  top:1.5rem;
  left: 12rem;
}

.run-in {
  top: 12rem;
  left: 24rem;
}

.hcm-in {
  top: 20rem;
  left: 22rem;
}

.enterprise-in {
  top: 15rem;
  left: 2rem;
}

.recruiting-in {
  top: 8rem;
  left: 1rem;
}

.nextgen-in {
  top: 6.5rem;
  left: 19rem;
}

@media (min-width: 640px) and (max-width: 750px) {
  .nextgen {
    top: 4.5rem;
    left: 17rem;
    width: 12rem;
  }
  .run {
    width: 10rem;
  }
}

@media (max-width: 768px) {
  .wfn {
    top: 1rem;
    left: 10rem;
  }
  .recruiting {
    top: 5rem;
    left: -5rem;
  }
  .nextgen {
    top: 4.5rem;
    left: 19rem;
  }
  .run {
    top: 11rem;
    left: 21rem;
  }
  .hcm {
    top: 17rem;
    left: 19rem;
  }
  .vantage {
    top: 22rem;
    left: 8rem;
  }
  .compliance {
    top: 11rem;
    left: -3rem;
  }
  .enterprise {
    top: 17rem;
    left: 0.5rem;
  }
}


/* @media (max-width: 600px) {
  .wfn {
    top: 0rem;
    left: 8rem;
  }
  .recruiting {
    top: 5rem;
    left: -5rem;
  }
  .nextgen {
    top: 4.5rem;
    left: 14rem;
  }
  .run {
    top: 11rem;
    left: 20rem;
  }
  .hcm {
    top: 16rem;
    left: 19rem;
  }
  .vantage {
    top: 21rem;
    left: 8rem;
  }
  .compliance {
    top: 11rem;
    left: -3rem;
  }
  .enterprise {
    top: 16rem;
    left: 0rem;
  }
  
} */

@media (max-width: 640px) {
  .wfn {
    top: -0.5rem;
    left: 7rem;
  }
  .recruiting {
    top: 4rem;
    left: -6rem;
  }
  .nextgen {
    top: 4rem;
    left: 14rem;
  }
  .run {
    top: 9rem;
    left: 16rem;
  }
  .hcm {
    top: 14rem;
    left: 15rem;
  }
  .vantage {
    top: 19rem;
    left: 5rem;
  }
  .compliance {
    top: 9rem;
    left: -5rem;
  }
  .enterprise {
    top: 14rem;
    left: -3rem;
  }
}
